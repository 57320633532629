import axios from '@axios'
import handlerDataSearch from '@/handlers/handlerDataSearch'


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addFile(ctx, { prst_uuid, fileData , camo_id = null}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/digital_file/digital_file_record/digital_file_vaccine_carnet/${prst_uuid}?id=${camo_id}`, fileData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, { elementData }) {
      console.log('saving parameters:', elementData)
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/vaccine/vaccine_schema/${elementData.id}`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements(ctx, params){
      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/vaccine/vaccine_schema', {params: currentParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_companies(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_excel_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/vaccine/vaccine_schema', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/vaccine/vaccine_schema/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', {
            params: { perPage: 500, page: 1, companyType: 'ARL' },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_company(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_headquarter(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/headquarter_by_id/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_service_bundles(ctx, { arl_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/service_bundle/by_arl/${arl_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type, deep, parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: { deep, parent },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_headquarters(ctx, { company_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/headquarter/${company_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_companies(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value_item(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 2 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, { parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 3, parent },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
