<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('list.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('list.entries') }}</label>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col cols="2">
                <span class="text-nowrap">{{
                  $t('arl_codes.filter.from')
                }}</span>
              </b-col>
              <b-col cols="10">
                <date-picker
                  :element-required="false"
                  element-field="filter_date_from"
                  :root-element-data="filter"
                  :element-clearable="true" />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col cols="2">
                <span class="text-nowrap">{{ $t('arl_codes.filter.to') }}</span>
              </b-col>
              <b-col cols="10">
                <date-picker
                  :element-required="false"
                  element-field="filter_date_to"
                  :root-element-data="filter"
                  :element-clearable="true" />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="4"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              @click="export2excel"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <vue-double-scrollbar v-if="tableDataLoaded">
        <b-table
          ref="refElementListTable"
          class="position-relative"
          :items="items"
          :fields="tableColumns"
          primary-key="identity"
          show-empty
          empty-text="No matching records found">
          <template #cell(id)="data">
            <span
              :id="`source-positive-${data.item.id}`"
              v-if="is_source_positive(data.item)"
              class="dot_purple"></span>
            <span
              :id="`patient-positive-${data.item.id}`"
              v-if="is_patient_positive(data.item)"
              class="dot_red"></span>

            <b-tooltip
              :target="`source-positive-${data.item.id}`"
              triggers="hover">
              <span class="align-middle ml-50">{{
                $t(RESOURCES_ELEMENT_NAME + '.source_positive')
              }}</span>
            </b-tooltip>
            <b-tooltip
              :target="`patient-positive-${data.item.id}`"
              triggers="hover">
              <span class="align-middle ml-50">{{
                $t(RESOURCES_ELEMENT_NAME + '.patient_positive')
              }}</span>
            </b-tooltip>
          </template>
          <!-- Column: names -->
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(accident_date)="data">
            {{ formatDate(data.item, 'accident_date') }}
          </template>
          <template #cell(report_date)="data">
            {{ formatDate(data.item, 'report_date') }}
          </template>
          <!-- Column: actions -->
          <template #cell(actions)="data">
            <b-col class="flex flex-row flex-wrap">
              <b-button
                style="margin: 1px"
                variant="primary"
                :id="`edit-button-${data.item.id}`"
                :to="{
                  name: 'apps-case-edit',
                  params: { id: data.item.id, origin: 'support' },
                }">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                style="margin: 1px"
                variant="primary"
                :id="`schema-button-${data.item.id}`"
                :to="{
                  name: 'vaccine-schema-edit',
                  params: { id: data.item.uuid },
                }">
                <feather-icon icon="ListIcon" />
              </b-button>
              <b-button
                style="margin: 1px"
                variant="primary"
                :id="`medical-order-${data.item.id}`"
                :to="{
                  name: 'apps-medical-order',
                  params: { case_id: data.item.id },
                }">
                <feather-icon icon="CrosshairIcon" />
              </b-button>

              <!-- ? EDIT -->
              <b-tooltip
                :target="`edit-button-${data.item.id}`"
                :title="$t('actions.edit')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
              <!-- ? SCHEMA -->
              <b-tooltip
                :target="`schema-button-${data.item.id}`"
                :title="$t('actions.vaccine_schema')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
              <!-- ? EDIT -->
              <b-tooltip
                :target="`medical-order-${data.item.id}`"
                :title="$t('actions.medical_order')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
            </b-col>
          </template>
        </b-table>
      </vue-double-scrollbar>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-if="tableDataLoaded"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div></div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from './filters.vue'
import store from '@/store'
import DatePicker from '@/views/core/DatePicker.vue'
import storeModule from '../storeModule'
import SessionStorage from '@/handlers/SessionStorage'
import * as XLSX from 'xlsx'
import moment from 'moment'
import debounce from 'lodash/debounce'

export default {
  components: {
    DatePicker,
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      currentPath: window.location.pathname,
      elementData: {},
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      filter: {
        filter_date_from: undefined,
        filter_date_to: undefined,
      },
      tableColumns: [
        {
          key: 'id',
          label: this.$t('case.object.identificator'),
          sortable: true,
        },
        {
          key: 'case_number',
          label: this.$t('case.object.number'),
          sortable: true,
        },
        {
          key: 'patient_identity',
          label: this.$t('case.object.patient_identity'),
          sortable: true,
        },
        {
          key: 'patient_name',
          label: this.$t('case.object.patient_name'),
          sortable: true,
        },
        {
          key: 'accident_date',
          label: this.$t('case.object.accident_date'),
          sortable: true,
        },
        {
          key: 'report_date',
          label: this.$t('case.object.report_date'),
          sortable: true,
        },
        {
          key: 'arl_name',
          label: this.$t('case.object.arl_name'),
          sortable: true,
        },
        {
          key: 'case_type_name',
          label: this.$t('case.object.case_type_name'),
          sortable: true,
        },
        {
          key: 'case_status_name',
          label: this.$t('case.object.case_status_name'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('case.object.actions'),
          thStyle: 'width: 300px;',
        },
      ],
      currentSelection: {},
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-vaccine',
      RESOURCES_ELEMENT_NAME: 'arl_codes',
      tableDataLoaded: false,
    }
  },
  watch: {
    perPage(val) {
      this.updateSessionStorage()
      this.debouncedFetchElements()
    },
    currentPage(val) {
      this.debouncedFetchElements()
    },
    query(val) {
      this.updateQueryAndFetch(val)
    },
    'filter.filter_date_from'(val) { 
      this.debouncedFetchElements()
    },
    'filter.filter_date_to'(val) {
      this.debouncedFetchElements()
    },
  },
  beforeDestroy() {
    this.$root.$off('delete-entity')
  },
  async mounted() {
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) {
      store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, storeModule)
    }

    this.loadStateFromSessionStorage()
    this.fetchElements()
  },
  unmounted() {
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) {
      store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
    }
  },
  methods: {
    loadStateFromSessionStorage() {
      const data = JSON.parse(sessionStorage.getItem(this.currentPath))
      if (data) {
        this.query = data.query || ''
        this.filter.filter_date_from = data.date_from || undefined
        this.filter.filter_date_to = data.date_to || undefined
      }
    },
    updateSessionStorage() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({
          query: this.query,
          date_from: this.filter.filter_date_from,
          date_to: this.filter.filter_date_to,
        })
      )
    },
    updateQueryAndFetch(newQuery) {
      this.query = newQuery
      this.updateSessionStorage()
      this.debouncedFetchElements()
    },
    debouncedFetchElements: debounce(function () {
      this.fetchElements()
    }, 300),
    fetchElements() {
      store
        .dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_elements', {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
            from: this.filter.filter_date_from,
            to: this.filter.filter_date_to,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
          this.tableDataLoaded = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async export2excel() {
      const filename = this.$t(
        `${this.RESOURCES_ELEMENT_NAME}.vaccine_schema_xls_name`
      )
      const result = await store.dispatch(
        this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_elements',
        {
          queryParams: {
            perPage: 10000,
            page: this.currentPage,
            query: this.query,
            from:
              this.filter.filter_date_from == 'Invalid date' ||
              this.filter.filter_date_from == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_from,
            to:
              this.filter.filter_date_to == 'Invalid date' ||
              this.filter.filter_date_to == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_to,
          },
        }
      )
      const items = this.formatItems(result.data.items)
      items.unshift(this.getHeader())
      const list = this.filterJson(items)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    formatItems(items) {
      return items.map((item) => ({
        ...item,
        id:
          item.source_positive && item.patient_positive
            ? 'Fuente positiva - Trabajador positivo'
            : item.source_positive
            ? 'Fuente positiva'
            : item.patient_positive
            ? 'Trabajador positivo'
            : '',
      }))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    is_source_positive(element) {
      return element.source_positive
    },
    is_patient_positive(element) {
      return element.patient_positive
    },
    formatDate(element, field) {
      return element[field]
        ? moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
        : ''
    },
    beforeGenerate() {
      console.info('Preparing data for export')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dot_red {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot_purple {
  height: 25px;
  width: 25px;
  background-color: purple;
  border-radius: 50%;
  display: inline-block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
